import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragulaModule } from 'ng2-dragula';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectdetailRoutingModule } from './projectdetail-routing.module';
import { ContactGridComponent } from './contact-grid/contact-grid.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MatButtonModule } from '@angular/material/button';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { RouterModule, Routes }   from '@angular/router';
import { ToggleButtonModule } from 'primeng/togglebutton';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatRadioModule } from '@angular/material/radio';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ComponentsModule } from '../shared/components/components.module';
import { RightSidebarComponent2 } from './right-sidebar/right-sidebar.component';
import {MatDividerModule} from '@angular/material/divider';

import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';

import { MatExpansionModule } from '@angular/material/expansion';

import { MegaMenuModule } from 'primeng/megamenu';
import { MenubarModule } from 'primeng/menubar';
import { MatBadgeModule } from '@angular/material/badge';
import { FeatherModule } from 'angular-feather';
import { PmNgModule } from '../primeng.module';
import { TreeSelectModule } from 'primeng/treeselect';

@NgModule({
  declarations: [

    ContactGridComponent,
    RightSidebarComponent2,
    LeftSidebarComponent

  ],
  imports: [
    MatSelectModule,
    CommonModule,
    MegaMenuModule,
    MatAutocompleteModule,
    MenubarModule,
    PmNgModule,
    ToggleButtonModule,
    RouterModule,
    MatBadgeModule,
    MatListModule,
    MatExpansionModule,
    MatDividerModule,
    FeatherModule,
    ProjectdetailRoutingModule,
    FullCalendarModule,
    NgScrollbarModule,
    MatButtonModule,
    NgxDatatableModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatCheckboxModule,
    MatInputModule,
    MatDialogModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatSnackBarModule,
    DragDropModule,
    FormsModule,
    MatSidenavModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSortModule,
    MatRadioModule,
    MatToolbarModule,
    MatTabsModule,
    MatMenuModule,
    MatCardModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DragulaModule.forRoot(),
    ComponentsModule,
    TreeSelectModule
  ]
})
export class ProjectdetailModule {}
