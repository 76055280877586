import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders, HttpContextToken,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import {logger} from "codelyzer/util/logger";



@Injectable()

export class JwtInterceptor implements HttpInterceptor {

  headerDict: any = {};
  requestOptions: { headers: HttpHeaders; };

  constructor(private authenticationService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const lang = this.language(localStorage.getItem('lang'));
    
    const currentUser = this.authenticationService.currentUserValue;
    
    const token = currentUser?.token;


    console.log(token);
    

    console.log("headerkontrol ust kat",request.headers.keys()[1]);

  if (request.headers.keys()[1]==undefined) {

    if (token) {
  
      request = request.clone({
        setHeaders: {
          'Accept-Language': lang,
          'Authorization': `Bearer  ${token}`,
          'Content-type': 'application/json',
        }
      })
      console.log("headerkontrol token",request.headers.keys()[1]);

    } else{
      request = request.clone({
        setHeaders: {
          'Accept-Language': lang,
          'Content-type': 'application/json',
        }
      })

      console.log("headerkontrol no token",request.headers.keys()[1]);
    }
    
  }

      return next.handle(request);

   
  }



  language(lang: string): any {
    if(lang=='tr' || lang==undefined || lang==null || lang=='' ) {
      return 'odiDil-tr'
    }
    if(lang=='en') {
      return 'odiDil-en'
    }
  }
}


