import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, concat, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';


import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private jwtHelper = new JwtHelperService();
   userData:any
  constructor(private http: HttpClient) {

    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );

    
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(telefonNumarasi: string, sifre: string) {
    let url = `${environment.apiUrl}/${environment.auth}/${environment.login}`
    return this.http.post<any>(url, {telefonNumarasi, sifre}).pipe(
        map((logindata) => {
        
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          if (logindata.statusCode==1016) {
            var decodedToken = this.jwtHelper.decodeToken(logindata.data.accessToken)
            var userInfo=JSON.parse(decodedToken.KullaniciBilgileri)
            var recordType=JSON.parse(decodedToken.KayitTurleri)

             this.userData={
              email:userInfo.Email,
              userName:userInfo.TamAdi,
              phone:userInfo.TelefonNumarasi,
              countryCode:userInfo.UlkeTelefonKodu,
              recordType:recordType[0].KayitTuru,
              recordTypeId:recordType[0].id,
              token:logindata.data.accessToken,
              userId:userInfo.Id,
              photo:userInfo.ProfilFotografi,
              statusCode:logindata.statusCode,
              mesaj:logindata.mesaj
        }

           localStorage.setItem('currentUser', JSON.stringify(this.userData));
           this.currentUserSubject.next(this.userData);
           return   this.userData;
          }
          
       
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
   localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}

