import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  AfterViewInit,
  Renderer2,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

import { SidebarService } from 'src/app/core/service/sidebar.service';
import { ConfigService } from 'src/app/config/config.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';

import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';

import { MenuItem } from 'primeng/api';




@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftSidebarComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, AfterViewInit
{
  @Output() leftSideBarOpen = new EventEmitter<boolean>();
  @Output ()  leftSide = new EventEmitter<boolean>();
  @Input ()  rightSide:any
  @Input () rightSideBarOpen:any
  selectedBgColor = 'black';
  maxHeight: string;
  maxWidth: string;
  showpanel = false;
  isOpenSidebar: boolean;
  isDarkSidebar = false;
  isDarTheme = false;
  public innerHeight: any;
  headerHeight = 60;
  isRtl = false;
  public config: any = {};

  items: MenuItem[];
  photo:any
  listMaxHeight: string;
  listMaxWidth: string;
 
  routerObj = null;
  userName: any;
  recordType: string;

  otherRightBarOpen = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: SidebarService,
    private configService: ConfigService
  ) {
    super();
  }

  ngOnInit() {

    this.items = [
      {
          label: 'AUDITION İSTE',
          icon: 'pi pi-fw pi-file',
          items: [ ]
      },
      {
          label: 'SUNUMA EKLE',
          icon: 'pi pi-fw pi-pencil',
          items: [ ]
      },
      {
          label: 'OPSİYON İSTE',
          icon: 'pi pi-fw pi-user',
          items: [ ]
      },
      {
          label: 'CALLBACK İSTE',
          icon: 'pi pi-fw pi-calendar',
          items: [ ]
      },
      {
        label: 'BİLDİRİM GÖNDER',
        icon: 'pi pi-fw pi-message',
        items: [ ]

    }
  ];


    this.config = this.configService.configData;
    this.subs.sink = this.rightSidebarService.sidebarState.subscribe(
      (isRunning) => {
        this.isOpenSidebar = isRunning;
      }
    );
    this.setRightSidebarWindowHeight();
  }
  ngAfterViewInit() {
    // set header color on startup

 
  }




  setRightSidebarWindowHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.maxHeight = height + '';
    this.maxWidth = '500px';
  }
  onClickedOutside(event: Event) {
    const button = event.target as HTMLButtonElement;
    if (button.id !== 'settingBtn') {
      if (this.isOpenSidebar === true) {
        this.toggleRightSidebar();
      }
    }
  }
  
  toggleRightSidebar(): void {
    this.rightSidebarService.setRightSidebar(
      (this.isOpenSidebar = !this.isOpenSidebar)
    );

 console.log(this.isOpenSidebar,"turuncu açıkmı");

 if (this.isOpenSidebar) {
  this.leftSide.emit(false)
 }

 if (!this.isOpenSidebar) {
  this.leftSide.emit(true)
 }
 
   
  }


  receveData(event:any){
    console.log(event,"gelen data");
  }





}
