import { Component, OnInit } from '@angular/core';
import { PrimeIcons, MenuItem } from 'primeng/api';

import { ActivatedRoute, Router } from '@angular/router';

import { Location } from '@angular/common';

interface City {
  name: string;
  code: string;
}


@Component({
  selector: 'app-contact-grid',
  templateUrl: './contact-grid.component.html',
  styleUrls: ['./contact-grid.component.scss']
})
export class ContactGridComponent implements OnInit {
  breadscrums = [
    {
      title: 'Odi Information',
      items: ['Project'],
      active: 'Odi Lİst'
    }
  ];

  cities: City[];

  selectedCity: City;

  star1:any
  star2:any
  star3:any
  star4:any

  isOpenLeftSideBar = false;

  islevmenu: MenuItem[];
  mainmenu: MenuItem[];

  showFiller = false;
  favoriselect: boolean = false;
  selectedOdi:boolean=false

projectId:any

menubar = false
filterbar = false
islevbar = false

maxHeight: string;
maxWidth: string;
rollist:any

showodi=true
favorid=true



  constructor( private route: ActivatedRoute,private location: Location,private router: Router) {


    this.star1 = Array(2).fill(0).map((x,i)=>i);
    this.star2 = Array(3).fill(0).map((x,i)=>i);
    this.star3 = Array(5).fill(0).map((x,i)=>i);
    this.star4 = Array(17).fill(0).map((x,i)=>i);


  }

  ngOnInit() {

    this.projectId=this.route.snapshot.paramMap.get('id');

    console.log(this.projectId,"projectId");
    

    this.cities = [
      { name: 'New York', code: 'NY' },
      { name: 'Rome', code: 'RM' },
      { name: 'London', code: 'LDN' },
      { name: 'Istanbul', code: 'IST' },
      { name: 'Paris', code: 'PRS' }
  ];


    this.islevmenu = [
        {
            label: 'AUDITION İSTE',
            icon: 'pi pi-fw pi-file',
            items: [ ]
        },
        {
            label: 'SUNUMA EKLE',
            icon: 'pi pi-fw pi-pencil',
            items: [ ]
        },
        {
            label: 'OPSİYON İSTE',
            icon: 'pi pi-fw pi-user',
            items: [ ]
        },
        {
            label: 'CALLBACK İSTE',
            icon: 'pi pi-fw pi-calendar',
            items: [ ]
        },
        {
          label: 'BİLDİRİM GÖNDER',
          icon: 'pi pi-fw pi-message',
          items: [ ]
  
      }
    ];

    this.getrollist()

    this.createmenu()


  }

  showprofil(){

    this.router.navigate(['profil/75'])

  }

  changeURL(): void {
    const path = '/new-path/123'; // The new path you want to set
    const queryParams = { id: '123' }; // Optional query parameters
  
    this.location.go(path);
  }

createmenu(){

  this.mainmenu = [
    {
        label: 'İZLE',
        icon: 'pi pi-fw pi-file',
        items: [ ]
    },
    {
        label: 'ÖNERİLER',
        icon: 'pi pi-fw pi-pencil',
        items: [ ]
    },
    {
        label: 'LİSTELER',
        icon: 'pi pi-fw pi-user',
        items: [   
          {
          label: 'AUDITION',
          icon: 'pi pi-fw pi-file',
          items: [ ]
        },
        {
          label: 'OPSİYON',
          icon: 'pi pi-fw pi-file',
          items: [ ]
         },
         {
          label: 'CALLBACK',
          icon: 'pi pi-fw pi-file',
          items: [ ]
          },
          {
            label: 'ONAY',
            icon: 'pi pi-fw pi-file',
            items: [ ]
          },
          {
            label: 'LİSTE OLUŞTUR',
            icon: 'pi pi-fw pi-plus',
            items: [ ]
            }
    
    ]
    },
    {
        label: 'ROLLER',
        icon: 'pi pi-fw pi-calendar',
        items: this.rollist
    },
    {
      label: 'MESAJLAR',
      icon: 'pi pi-spin pi-envelope',
      items: [ ]
    },
    {
      label: 'PROJE AYARLARI',
      icon: 'pi pi-spin pi-cog',
      items: [ ]
    }
];
  }

  getrollist(){
  
    this.rollist=[
      {
     label: 'ROL1',
     id:1,
     icon: 'pi pi-fw pi-file',
     command: () => {
      this.menuclick(1);
      }
   
   },
   {
     label: 'ROL2',
     icon: 'pi pi-fw pi-file',
     command: () => {
      this.menuclick(2);
      }

   },
   {
     label: 'ROL3',
     icon: 'pi pi-fw pi-file',
     command: () => {
      this.menuclick(3);
      }
  
   },
   {
     label: 'ROL4',
     icon: 'pi pi-fw pi-file',
     command: () => {
      this.menuclick(4);
      }
 
   }

 
  ]

  }

  dene(){
 console.log("dene");
 

  }

  ngAfterViewInit() {
console.log("ngAfterViewInit")
  }

  handleMyEvent(sideBar: any) {
    this.isOpenLeftSideBar = sideBar;
 }



 selectodi(select:any){

  this.showodi=!this.showodi

    this.selectedOdi=select
 }

 addfavori(select:any){

  this.favorid=!this.favorid

  console.log( this.favorid,"favoriid");
  

 }

 openrightFilter(){


 }

 menuclick(event:any){

  console.log(event,"menu click");
  

 }


}
