<app-right-sidebar2 (rightSide)="receveData($event)"></app-right-sidebar2>




<div class="settingSidebar" [ngClass]="isOpenSidebar ? 'showSettingPanel' : ''">
  <a href="javascript:void(0)" class="settingPanelToggle"
     [ngClass]="rightSideBarOpen?'d-none':''" 
     style="background: #ff8502 !important; margin-top: 5rem;" (click)="toggleRightSidebar()">
    <mat-icon matSuffix>menu</mat-icon>
  </a>




 
  <ng-scrollbar [style.height]="maxHeight + 'px'" visibility="hover">
    <div class="settingSidebar-body ps-container ps-theme-default">
      <div class=" fade show active">
        <div class="setting-panel-header"  style="background: #ff8502;color: white; text-align: center;" >İŞLEV SEÇ
        </div>


        <div class="card flex justify-content-center">
          <p-panelMenu [model]="items" [style]="{'width':'300px'}"></p-panelMenu>
      </div>
        

   
      


     
      </div>
    </div>
  </ng-scrollbar>
</div>






