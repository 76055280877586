


<section >
  <div class="container-fluid">

    <div class="row clearfix">
      <div   class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
  

          <div class="header">
            <div class="materialTableHeader">
              <div class="row">
                <div class="col-12">
                  <ul class="header-buttons-left ms-0">

                    <li>
                      <button class="btn  m-l-10"  style="background: #ff8502;color: white; text-align: center;" type="button" (click)="menubar = true">  <i class="fas fa-bars align-center"></i></button>        
                  </li>

                    <li class="tbl-title">
                      <img src="assets/images/logo/odilogo-01.png" style="width: 40px; height: 20px;" alt="" />
                    </li>
                    <li class="tbl-search-box">
                      <label for="search-input"><i class="material-icons search-icon" >search</i></label>
                      <input placeholder="Search" type="text" #filter class="browser-default search-field"
                        aria-label="Search box" style="width: 600px;">
                    </li>

                 

                    <li>
                      <button class="btn   m-l-10"  style="background: #6777ef;color: white; text-align: center" type="button" (click)="filterbar = true">  <i class="fas fa-filter align-center"></i></button>        
                  </li>

                  <li>
                    <button class="btn   m-l-10" style="background: #ff8502;color: white; text-align: center;" type="button" (click)="islevbar = true">  <i class="fas fa-cogs align-center"></i></button>        
                </li>
              
                  </ul>
                </div>
           
              </div>
            </div>
          </div>

          


        
        <div class="row">     
          <div *ngFor="let item of star4; let i index"  class="col-xs-12 col-sm-3 col-md-3 col-lg-3 m-t-10">
            <mat-card >
              <mat-card-header  [ngStyle]="{ 'background-color': i==3 ? 'grey' : 'grey' }"  style="background-color:grey;color: #fff;">
                <p-inputSwitch mat-card-avatar [(ngModel)]="checked"></p-inputSwitch>
             
                <mat-card-title>Esma Sultangülü {{i}}</mat-card-title>
                <mat-card-subtitle style="color: #fff;">Antalya
                  <div style="  margin-top: -15px; text-align: right; font-size: 12px;"  >
                    <i *ngFor="let item of star3" class="fa fa-star"  aria-hidden="true" style="color: yellow"></i>
                    <span style="color:greenyellow;margin-left: 5px;" > 4,8</span>
                  </div>
                </mat-card-subtitle>
              </mat-card-header>

              <img mat-card-image src="https://odiapp.s3.eu-central-1.amazonaws.com/KullaniciDosyalari/75/Fotograflar/1674223435318_Ads%C4%B1z_tasar%C4%B1m%20(1).jpg">
              <mat-card-content>
                  <div class="row">
          
                    <div class="col-lg-1 ">
                      <i class="material-icons" > personel </i>
                    </div>
                    <div class="col-lg-3 m-t-5 m-l-3 align-left" style="margin-left: 3px;">
                      YAŞ : <strong>23</strong>
                    </div>
                    <div class="col-lg-4 m-t-5 align-center ">
                      BOY : <strong>163</strong>
                    </div>
                    <div class="col-lg-4 m-t-5 align-right" style="margin-left: -25px;">
                      KİLO :<strong>87</strong>
                    </div>
    
                  </div>

                   <mat-divider></mat-divider>

             <div class="row">
              <div class="col-lg-2 m-t-5">
                <i class="material-icons" > school </i>
              </div>
              <div class="col-lg-10 m-t-5" style="margin-left: -20px;">
                <span>Eğitim</span>
              </div>
             </div>

             <div class="row">
              <div class="col-lg-2">
                <i class="material-icons"> group_work </i>
              </div>
              <div class="col-lg-10" style="margin-left: -20px;">
                <span>Tecrube</span>
              </div>
             </div>
         
              </mat-card-content>
              <mat-divider></mat-divider>
              <mat-card-actions style="justify-content: center">
                <div fxLayout="row">
             

                  <button mat-icon-button *ngIf="!favoriselect" (click)="addfavori()">
                    <div class="demo-google-material-icon">
                      <i *ngIf="favorid" class="material-icons">favorite_border</i>   
                      <i *ngIf="!favorid"  class="material-icons col-red">favorite</i>
                    </div>
                  </button>


                  <button mat-icon-button  (click)="showprofil()" > 
                    <div class="demo-google-material-icon">
                      <i class="material-icons">edit</i>
                    </div>
                  </button>
                  <button mat-icon-button>
                    <div class="demo-google-material-icon">
                      <i class="material-icons">email</i>
                    </div>
                  </button>

                  <button mat-icon-button  (click)="selectodi(true)">
                    <div class="demo-google-material-icon">
                      <i  *ngIf="showodi"  style="color: green;" class="material-icons">visibility</i>
                      <i  *ngIf="!showodi"  style="color: red;" class="material-icons">visibility_off</i>
                    </div>
                  </button>

            

                  <button mat-icon-button  (click)="selectodi(false)">
                    <div class="demo-google-material-icon">
                      <i class="material-icons ">cancel</i>
                    </div>
                  </button>



                  
                  <span fxFlex></span>
                </div>
              </mat-card-actions>
            </mat-card>
          </div>
       </div>
    
      </div>
    </div>
  </div>
</section>


<p-sidebar [(visible)]="menubar"  position="left">

  <div class="settingSidebar showmenuPanel"  >

    <ng-scrollbar [style.height]="maxHeight + 'px'" visibility="hover">
      <div class="settingSidebar-body ps-container ps-theme-default">
        <div class=" fade show active">
          <div class="setting-panel-header" style="background: #ff8502;color: white; text-align: center; " > <i class="fas fa-bars align-center" style="margin-right: 10px;"></i>MENÜ

          </div>

          <div class="card flex justify-content-center">
            <p-panelMenu [model]="mainmenu" [style]="{'width':'100%'}" (click)="menuclick($event)"></p-panelMenu>
        </div>
  

  
       
        </div>
      </div>
    </ng-scrollbar>
  </div>

 

</p-sidebar>

<p-sidebar  [(visible)]="filterbar"  position="right">


  <div class="settingSidebar showSettingPanel "  >

    <ng-scrollbar [style.height]="maxHeight + 'px'" visibility="hover">
      <div class="settingSidebar-body ps-container ps-theme-default">
   
          <div class="setting-panel-header " style="background: #6777ef;color: white; text-align: center;"> <i class="fas fa-filter align-center" style="margin-right: 10px;"></i> Filter Panel
          </div>


        <!--   <div class="col-12">
            <p-dropdown  styleClass="w-full" [options]="cities" [(ngModel)]="selectedCity" optionLabel="name"></p-dropdown>
          </div>
 -->
          <div class="col-12" >
            <mat-form-field class="example-full-width" appearance="outline" >
              <mat-label>Uyruk</mat-label>
              <mat-select >
                <mat-option value="option1">Tc</mat-option>
                <mat-option value="option2">Diğer</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row col-12 m-t--25 "  style="margin-left: 0px;">

          <div class="col-6 align-left">
          <mat-form-field class="example-full-width " appearance="outline">
            <mat-label>Min Yaş</mat-label>
            <input matInput>
          </mat-form-field>
        </div>

        <div class="col-6 align-right">
          <mat-form-field class="example-full-width " appearance="outline">
            <mat-label>Max Yaş</mat-label>
            <input matInput>
          </mat-form-field>
        </div>

      </div>


      <div class="row col-12 m-t--25 "  style="margin-left: 0px;">

        <div class="col-6 align-left">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Min Boy</mat-label>
          <input matInput>
        </mat-form-field>
      </div>

      <div class="col-6 align-right">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Max Boy</mat-label>
          <input matInput>
        </mat-form-field>
      </div>

    </div>


    <div class="row col-12 m-t--25 "  style="margin-left: 0px;">

      <div class="col-6 align-left">
      <mat-form-field class="example-full-width " appearance="outline">
        <mat-label>Min Kilo</mat-label>
        <input matInput>
      </mat-form-field>
    </div>

    <div class="col-6 align-right">
      <mat-form-field class="example-full-width " appearance="outline">
        <mat-label>Max Kilo</mat-label>
        <input matInput>
      </mat-form-field>
    </div>

  </div>

        <div class="col-12 m-t--25" >

          <mat-form-field class="example-full-width  " appearance="outline" >
            <mat-label>Eğitim</mat-label>
            <mat-select >
              <mat-option value="option1">Option 1</mat-option>
              <mat-option value="option2">Option 2</mat-option>
            </mat-select>
          </mat-form-field>
        </div>


        <div class="col-12 m-t--25" >

          <mat-form-field class="example-full-width " appearance="outline" >
            <mat-label>Deneyim</mat-label>
            <mat-select >
              <mat-option value="option1">Option 1</mat-option>
              <mat-option value="option2">Option 2</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-12 m-t--25" >

          <mat-form-field class="example-full-width " appearance="outline" >
            <mat-label>Olması Gerekenler</mat-label>
            <mat-select >
              <mat-option value="option1">Option 1</mat-option>
              <mat-option value="option2">Option 2</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-12 " >

          <button class="btn m-l-10 "   style="background: #6777ef;color: white; text-align: right;float: right" type="button" (click)="filterbar = true">  <i class="fas fa-filter align-center"></i> Uygula</button>        

        </div>

     

      
      </div>
    </ng-scrollbar>

    

  </div>

  

</p-sidebar>


<p-sidebar [(visible)]="islevbar"  position="right">

  <div class="settingSidebar showSettingPanel"  >

    <ng-scrollbar [style.height]="maxHeight + 'px'" visibility="hover">
      <div class="settingSidebar-body ps-container ps-theme-default">
        <div class=" fade show active">
          <div class="setting-panel-header " style="background: #ff8502;color: white; text-align: center;" > <i class="fas fa-cogs align-center " style="margin-right: 10px;"></i>İŞLEV SEÇ

          </div>
  
                <div class="card flex justify-content-center">
          <p-panelMenu [model]="islevmenu" [style]="{'width':'100%'}"></p-panelMenu>
      </div>
  
       
        </div>
      </div>
    </ng-scrollbar>
  </div>
</p-sidebar>




