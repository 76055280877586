import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class SidebarService {

  private sidebarSubject: BehaviorSubject<any> = new BehaviorSubject(0);

  sidebarState = this.sidebarSubject.asObservable();

  setRightSidebar = (value:any) => {

    this.sidebarSubject.next(value);

  };

  public get sidebarstatus(){
    return this.sidebarSubject.value;
  }




  constructor() {}
}
