// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://core.adakod.com',
  auth: 'auth',
  sifremiunuttum: 'sifremiunuttum',
  kullanicikayit: 'kullanicikayit',
  login: 'login',
  odiuygulamaayarlari: 'odiuygulamaayarlari',
  aktifdillistesi: 'aktifdillistesi',
  telefonkodlarilistesi: 'telefonkodlarilistesi',
  kayitturleri: 'kayitturleri',
  SMSGonder: 'SMSGonder',
  odiprojeyonetimi: 'odiprojeyonetimi',
  projeolusturmaayarlari: 'projeolusturmaayarlari',
  yeniproje: 'yeniproje',
  projeguncelle: 'projeguncelle',
  dosyayuklemelinkial: 'odidosya/dosyayuklemelinkial',
  projeFotografiGuncelle: 'projeFotografiGuncelle',
  projefirmasifotografarama: 'projefirmasifotografarama',
  rolBilgisiKayit: "rolbilgisikayit",
  yetkiliProjeListesi: "yetkiliprojelistesi",
  projeGetir: "projegetir",
  projeRolOzellikEkle: "projerolozellikekle",
  projeRolOdiEkle: "projerolodiekle",
  fotomatikyonetmennotuekle: "fotomatik-yonetmen-notu-ekle",
  projeRolOdiFotoEkle: "projerolodifotoekle",
  projeRolOdiFotoGetir: "projerolodifotogetir",
  projeRolOdiSesEkle: "projerolodisesekle",
  projeRolOdiSesGetir: "projerolodisesliste",
  projeBasicBilgiGetir: "projebasicbilgigetir",
  fotomatikornekfotoekle: 'fotomatik-ornek-foto-ekle',
  fotomatikpozekle: 'fotomatik-poz-ekle',
  sorumatikaciklamaekle: 'sorumatik-aciklama-ekle',
  sorumatiksoruekle: 'sorumatik-soru-ekle',
  sesmatiksenaryoekle: 'sesmatik-senaryo-ekle',
  sesmatikyonetmennotuekle: 'sesmatik-yonetmen-notu-ekle',
  sesmatiksesekle: 'sesmatik-ses-ekle',
  videomatikyonetmennotuekle: 'videomatik-yonetmen-notu-ekle',
  videomatikyonetmennotuguncelle: 'videomatik-yonetmen-notu-guncelle',
  videomatiksenaryoekle: 'videomatik-senaryo-ekle',
  videomatiksenaryosil: 'videomatik-senaryo-sil',
  videomatiksenaryoguncelle: 'videomatik-senaryo-guncelle',
  videomatikornekoyunekle: 'videomatik-ornek-oyun-ekle',
  videomatikornekoyunguncelle: 'videomatik-ornek-oyun-guncelle',
  videomatikornekoyunsil: 'videomatik-ornek-oyun-sil',
  videomatikvideoekle: 'videomatik-video-ekle',
  videomatikvideoguncelle: 'videomatik-video-guncelle',
  videomatikvideodetayekle: 'videomatik-video-detay-ekle',
  videomatikvideodetayguncelle: 'videomatik-video-detay-guncelle',
  videomatikvideodetaysil: 'videomatik-video-detay-sil',
  projebasicbilgigetir: 'projebasicbilgigetir',
  yetkiliacikprojelistesi: "yetikili-acik-proje-listesi",
  yetkilikapanmisprojelistesi: 'etikili-kapanmis-proje-listesi',
  odiprofil: 'odiprofil',
  profilgetir: 'profilgetir',
  profilhakkimdaguncelle: 'profilhakkimdaguncelle',
  profilfotografiguncelle: 'profilfotografiguncelle',
  profilvideosuekle: 'profilvideosuekle',
  profilvideosuliste: 'profilvideosuliste',
  odicv: 'odicv',
  cvayarlarigetir: 'cvayarlarigetir',
  cvlistesi: 'cvlistesi',
  cvolustur: 'cvolustur',
  odialbum:'odialbum',
  albumliste:'albumliste'


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
