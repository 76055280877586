import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';

import { ContactGridComponent } from './projectdetail/contact-grid/contact-grid.component';

import { ProfildetailComponent } from './Profil/profildetail/profildetail.component';




const routes: Routes = [



  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { 
        path: '', redirectTo: '/authentication/signin', pathMatch: 'full' 
      },

      {
        path: 'projects',
        loadChildren: () =>
          import('./projects/projects.module').then((m) => m.ProjectsModule)
      },
      {
        path: 'profil',
        loadChildren: () =>
          import('./Profil/profil.module').then(
            (m) => m.ProfilModule)
      }
   
    ]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },

  {
    path: 'projects/:id',
    component:ContactGridComponent,
    loadChildren: () =>
      import('./projectdetail/projectdetail.module').then(
        (m) => m.ProjectdetailModule)
  },




  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
