import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  AfterViewInit,
  Renderer2,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

import { SidebarService } from 'src/app/core/service/sidebar.service';
import { ConfigService } from 'src/app/config/config.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';


@Component({
  selector: 'app-right-sidebar2',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightSidebarComponent2
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, AfterViewInit
{

  @Output() rightSideBarOpen = new EventEmitter<boolean>();
  @Output() rightSide = new EventEmitter<boolean>();
  @Input () leftSide:any
  @Input () leftSideBarOpen:any
  
  selectedBgColor = 'black';
  maxHeight: string;
  maxWidth: string;
  showpanel = false;
  isOpenSidebar: boolean;

   sidebarstatus=0

   otherRightBarOpen = false;


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService:SidebarService,
    private configService: ConfigService
  ) {
    super();
  }

  ngOnInit() {
    
    this.subs.sink = this.rightSidebarService.sidebarState.subscribe(
      (isRunning) => {
        this.isOpenSidebar = isRunning;
      }
    );
 
  }
  ngAfterViewInit() {



  }





  toggleRightSidebar(): void {

    this.rightSidebarService.setRightSidebar(
      (this.isOpenSidebar = !this.isOpenSidebar)
    );

    if (this.isOpenSidebar) {
      this.rightSide.emit(false)
     }

     if (!this.isOpenSidebar) {
      this.rightSide.emit(true)
     }





  }



}
