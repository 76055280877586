
<div class="settingSidebar"  [ngClass]="isOpenSidebar ? 'showSettingPanel' : ''">
  <a href="javascript:void(0)"  [ngClass]="leftSideBarOpen?'d-none':''"  class="settingPanelToggle" (click)="toggleRightSidebar()">
  <i class="fas fa-filter align-center"></i>
  </a>

  

 
  <ng-scrollbar [style.height]="maxHeight + 'px'" visibility="hover">
    <div class="settingSidebar-body ps-container ps-theme-default">
      <div class=" fade show active">
        <div class="setting-panel-header " style="background: #6777ef;color: white; text-align: center;">Filter Panel
        </div>

        

     
      </div>
    </div>
  </ng-scrollbar>
</div>
